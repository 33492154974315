<template>
  <v-container class="custom-container">
    <TheBreadcrumb/>
    <v-fade-transition mode="out-in">
      <router-view/>
    </v-fade-transition>
  </v-container>
</template>

<script>
import TheBreadcrumb from "@/components/Breadcrumb/TheBreadcrumb";

export default {
  name: "InformacionView",
  components: {TheBreadcrumb},
}
</script>

<style scoped>

</style>